import { Route, Switch } from 'react-router'
import React, { Suspense } from 'react'
import POconstants from '../Utils/POconstants.js'
import PageIsLoading from '../Components/Common/PageIsLoading/PageIsLoading'

export default function PlanAVisitRouter() {
    const ServiceInfo = React.lazy(() => import('../Components/Pages/PlanAVisit/ServiceInfo/ServiceInfo'))
    const Diving = React.lazy(() => import('../Components/Pages/PlanAVisit/Diving/Diving'))
    const RomanVilla = React.lazy(() => import('../Components/Pages/PlanAVisit/RomanVilla/RomanVilla'))
    const Footpaths = React.lazy(() => import('../Components/Pages/PlanAVisit/Footpaths/Footpaths'))
    const Beaches = React.lazy(() => import('../Components/Pages/PlanAVisit/Beaches/Beaches'))
    const Sailing = React.lazy(() => import('../Components/Pages/PlanAVisit/Sailing/Sailing'))
    const LazyPageNotFound = React.lazy(() => import('../Components/Pages/NoPage/NoPage'))
    const LazyHospitality = React.lazy(() => import('../Components/Pages/PlanAVisit/Hospitality/Hospitality'))
    const LazyVineyards = React.lazy(() => import('../Components/Pages/PlanAVisit/Vineyards/Vineyards'))
    const LazyBotanicGarden = React.lazy(() => import('../Components/Pages/PlanAVisit/BotanicGarden/BotanicGarden'))
    return (
        <>
            <Suspense fallback={<PageIsLoading />}></Suspense>
            <Switch>
                <Route path={POconstants.siteLinks.diving} component={Diving} />
                <Route path={POconstants.siteLinks.romanVilla} component={RomanVilla} />
                <Route path={POconstants.siteLinks.footpath} component={Footpaths} />
                <Route path={POconstants.siteLinks.beaches} component={Beaches} />
                <Route path={POconstants.siteLinks.sailing} component={Sailing} />
                <Route path={POconstants.siteLinks.serviceInfo} component={ServiceInfo} />
                <Route path={POconstants.siteLinks.hospitality} component={LazyHospitality} />
                <Route path={POconstants.siteLinks.vineyards} component={LazyVineyards} />
                <Route path={POconstants.siteLinks.botanicGarden} component={LazyBotanicGarden} />
                <Route path="*" component={LazyPageNotFound} />
            </Switch>
        </>
    )
}
