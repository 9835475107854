import React from 'react'
import useDropDown from './useDropDown'
import { Link } from 'react-router-dom'

const DropDown = (props: NavBar2.DropDown.Import) => {
    const service = useDropDown(props)
    return service.visible || !props.isChild ? (
        <div className={'dropDown' + (!props.isChild ? '' : ' ddchild') + (props.class ? ' ' + props.class : '')} ref={service.ref}>
            {service.buttonData &&
                service.buttonData.map((button) => {
                    return (
                        <div
                            className="dropDownContainer"
                            key={button.key}
                            onMouseEnter={
                                service.mobileLayoutActive
                                    ? undefined
                                    : () => {
                                          button.handler(true)
                                      }
                            }
                            onMouseLeave={
                                service.mobileLayoutActive
                                    ? undefined
                                    : () => {
                                          button.handler(false)
                                      }
                            }
                        >
                            {button.link && (
                                <Link
                                    className="dropDownButton"
                                    to={button.link}
                                    onClick={async (e) => {
                                        await service.resetMousedOver()
                                        if (props.parentResetHandler) await props.parentResetHandler()
                                        if (button.onClick) button.onClick(e)
                                    }}
                                >
                                    {button.title}
                                </Link>
                            )}
                            {!button.link && button.externalLink && (
                                <a
                                    className="dropDownButton"
                                    href={button.externalLink}
                                    onClick={button.onClick}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {button.title}
                                </a>
                            )}
                            {!button.link && !button.externalLink && (
                                <div
                                    className="dropDownButton"
                                    onTouchStart={async (e) => {
                                        await service.resetMousedOver()
                                        button.handler(!button.showSubMenu)
                                        if (props.parentResetHandler) await props.parentResetHandler()
                                        if (button.onClick) {
                                            button.onClick(e)
                                        }
                                    }}
                                    style={{ cursor: button.onClick ? 'pointer' : 'context-menu' }}
                                >
                                    {button.title}
                                    <i className={'fas fa-caret-' + (button.isInNavBar ? 'down' : 'right')}></i>
                                </div>
                            )}

                            {button.subMenus && (
                                <DropDown
                                    isChild
                                    parentSaysShouldShow={button.showSubMenu}
                                    toggleShowParent={service.childSaysShouldShowHandler}
                                    parentResetHandler={async () => {
                                        await service.resetMousedOver()
                                        if (props.parentResetHandler) await props.parentResetHandler()
                                    }}
                                    options={button.subMenus}
                                />
                            )}
                        </div>
                    )
                })}
        </div>
    ) : null
}

export default DropDown
