import { useState, useEffect, useRef } from 'react'

const useDropDown: NavBar2.DropDown.Service = props => {
    const [isAnimating] = useState<boolean>(false)
    const [childSaysShouldShow, childSaysShouldShow_set] = useState<boolean>(false)
    const [mobileLayoutActive, mobileLayoutActive_set] = useState<boolean>(window.innerWidth < 1180)
    const [elementHeight, elementHeight_set] = useState<number>()
    const [displayPhase, displayPhase_set] = useState<'hidden' | 'showStart' | 'measured' | 'shown'>('hidden')
    const ref = useRef<HTMLDivElement>(null)
    const [buttonData, buttonData_set] = useState<NavBar2.DropDown.Service.ButtonData[]>(
        props.options.map(option => {
            return {
                ...option,
                buttonMousedOver: false,
                handler: (shouldShow: boolean) => toggleShowChildOnButtonMouseover(option.key, shouldShow),
                showSubMenu: false,
                key: option.key,
            }
        })
    )
    const mobileLayoutShouldBeActive = window.innerWidth < 1180
    if (mobileLayoutActive !== mobileLayoutShouldBeActive) mobileLayoutActive_set(mobileLayoutShouldBeActive)
    //rese tMousedOver state when url changes
    const resetMousedOver = () => {
        buttonData_set(currentData =>
            currentData.map(datum => {
                return { ...datum, buttonMousedOver: false }
            })
        )
    }
    const mobileClickHandler = (key: string) => {
        const currentState = buttonData.find(datum => datum.key === key)
        if (currentState === undefined || !mobileLayoutActive) return null
        toggleShowChildOnButtonMouseover(key, !currentState.buttonMousedOver)
    }
    useEffect(() => {
        const newButtonData = props.options.map((option, index) => {
            return {
                ...option,
                buttonMousedOver: false,
                handler: (shouldShow: boolean) => toggleShowChildOnButtonMouseover(option.key, shouldShow),
                showSubMenu: false,
                key: option.key,
            }
        })
        buttonData_set(newButtonData)
    }, [props.options])
    const childSaysShouldShowHandler = (shouldShow: boolean) => {
        childSaysShouldShow_set(shouldShow)
    }
    const toggleShowChildOnButtonMouseover = (key: string, shouldShow: boolean) => {
        buttonData_set(currentData => {
            const tempData = currentData
            const modifiedData = tempData.map(datum => {
                if (datum.key === key) {
                    return {
                        ...datum,
                        showSubMenu: shouldShow,
                        buttonMousedOver: shouldShow,
                    }
                } else return datum
            })
            return modifiedData
        })
    }
    const showDropDown = buttonData.filter(buttonDatum => buttonDatum.buttonMousedOver === true).length > 0
    const [visible, visible_set] = useState<boolean>(false)
    const hideDropdown = () => {
        if (ref.current) {
            const dropDownElement = ref.current
            dropDownElement.setAttribute('style', '')
            displayPhase_set('hidden')
        }
    }
    useEffect(() => {
        const dropDownElement = ref.current
        if (dropDownElement) {
            switch (displayPhase) {
                case 'showStart':
                    elementHeight_set(dropDownElement.getBoundingClientRect().height)
                    dropDownElement.setAttribute('style', 'height: 0px; overflow: hidden;')
                    displayPhase_set('measured')
                    break
                case 'measured':
                    setTimeout(() => {
                        dropDownElement.setAttribute(
                            'style',
                            'height: ' + elementHeight + 'px; opacity: 1; transition: height 0.8s; overflow: hidden;'
                        )
                        elementHeight_set(undefined)
                        displayPhase_set('shown')
                    }, 200)
                    break
                case 'shown':
                    setTimeout(() => dropDownElement.setAttribute('style', 'opacity: 1;'), 800)
                    break
            }
        }
    }, [displayPhase, elementHeight])
    useEffect(() => {
        if (mobileLayoutActive) {
            if (props.parentSaysShouldShow !== visible && props.isChild) {
                let newVisible = props.parentSaysShouldShow
                visible_set(newVisible)
                newVisible ? displayPhase_set('showStart') : hideDropdown()
            }
        } else {
            if ((props.parentSaysShouldShow || showDropDown || childSaysShouldShow) !== visible && props.isChild) {
                let newVisible = props.parentSaysShouldShow || showDropDown || childSaysShouldShow
                visible_set(newVisible)
                newVisible ? displayPhase_set('showStart') : hideDropdown()
            }
        }
    }, [props.parentSaysShouldShow, showDropDown, childSaysShouldShow, props.isChild, visible, mobileLayoutActive])

    //buttonData.forEach(datum => console.log('===key, mousedOver===\n', datum.key, datum.buttonMousedOver))
    return {
        buttonData,
        childSaysShouldShow,
        childSaysShouldShowHandler,
        showDropDown,
        isAnimating,
        mobileClickHandler,
        mobileLayoutActive,
        mobileLayoutActive_set,
        resetMousedOver,
        visible,
        ref,
    }
}

export default useDropDown
