import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import "./App.css";
import "./App.scss";
import NavBar2 from "./Components/NewNavBar2/NavBar";
import { StateProvider } from "./extensions/StateManager";
import { useLocale } from "./extensions/Locale/useLocale";
import hr from "./extensions/Locale/hr";
import en from "./extensions/Locale/en";
import ToolBar from "./Components/ToolBar/ToolBar";
import PakleniIslandsRouter from "./Routers/PakleniIslandsRouter";
import PlanAVisitRouter from "./Routers/PlanAVisitRouter";
import ExploreRouter from "./Routers/ExploreRouter";
import NewsRouter from "./Routers/NewsRouter";
import Footer from "./Components/Footer/Footer";
import POconstants from "./Utils/POconstants";
import PageIsLoading from "./Components/Common/PageIsLoading/PageIsLoading";
import ReactGA from "react-ga";
import { getJsonFromUrl } from "./Utils/ClientUtil";
import { Helmet } from "react-helmet";

function App() {
  return (
    <StateProvider>
      <BrowserRouter>
        <AppBody />
      </BrowserRouter>
    </StateProvider>
  );
}

function AppBody(props: any) {
  const { setLanguages } = useLocale<Locale.Keys>();
  const [localeLoaded, localeLoaded_set] = useState<boolean>(false);
  const URLLang = getJsonFromUrl(window.location.href).lang;
  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" ||
      process.env.REACT_APP_ENVIRONMENT === "test"
    ) {
      const trackingId = process.env.REACT_APP_GA_ID;
      if (trackingId) {
        ReactGA.initialize(trackingId);
      } else console.error("Unable to load Google analytics id env variable");
    }
    console.log(
      "===window.location.search===",
      window.location.search.slice(6, 7)
    );
    let currentLang: "en" | "hr" = URLLang === "en" ? "en" : "hr";
    let lang: Locale.State<Locale.Keys> = {
      langKey: currentLang,
      hr: hr(),
      en: en(),
    };
    setLanguages(lang);
    localeLoaded_set(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLLang]);
  const history = useHistory();
  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" ||
      process.env.REACT_APP_ENVIRONMENT === "test"
    ) {
      console.log("===GA MARK VISIT===", history.location.pathname);
      ReactGA.set({ page: history.location.pathname });
      ReactGA.pageview(history.location.pathname);
    }
  }, [history.location.pathname]);

  const LazyHome = React.lazy(
    () => import("./Components/Pages/HomePage/HomePage")
  );
  const lazyDYST = React.lazy(
    () => import("./Components/Pages/DidYouSeeThis/DidYouSeeThis")
  );
  const lazyPrivacy = React.lazy(
    () => import("./Components/Pages/Privacy/Privacy")
  );
  const lazyTouristMap = React.lazy(
    () => import("./Components/Pages/PlanAVisit/TouristMap/TouristMap")
  );
  const lazyAboutUs = React.lazy(
    () => import("./Components/Pages/AboutUs/AboutUs")
  );
  const lazyContact = React.lazy(
    () => import("./Components/Pages/Contact/Contact")
  );
  const lazyPageNotFound = React.lazy(
    () => import("./Components/Pages/NoPage/NoPage")
  );
  const lazyReports = React.lazy(
    () => import("./Components/Pages/ReportMap/ReportMap")
  );
  const lazyImpreco = React.lazy(
    () => import("./Components/Pages/Impreco/Impreco")
  );
  return localeLoaded ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title></title>
        <meta name="description" content="" />
        <link rel="canonical" href={POconstants.siteLinks.home} />
      </Helmet>
      {!history.location.pathname.includes("/elearning") && <ToolBar />}
      <Suspense fallback={<PageIsLoading />}>
        {!history.location.pathname.includes("/elearning") && (
          <Route path="*" component={NavBar2} />
        )}
        <div className="page">
          <Switch>
            <Route path="/PakleniIslands/" component={PakleniIslandsRouter} />
            <Route path="/PlanAVisit/" component={PlanAVisitRouter} />
            <Route path="/ExplorePakleniIslands/" component={ExploreRouter} />
            <Route path="/News/" component={NewsRouter} />
            <Route
              path={POconstants.siteLinks.touristMap}
              component={lazyTouristMap}
            />
            <Route
              path={POconstants.siteLinks.didYouSeeThis}
              component={lazyDYST}
            />
            <Route
              path={POconstants.siteLinks.privacy}
              component={lazyPrivacy}
            />
            <Route
              path={POconstants.siteLinks.aboutUs}
              component={lazyAboutUs}
            />
            <Route
              path={POconstants.siteLinks.contact}
              component={lazyContact}
            />
            <Route
              path={POconstants.siteLinks.reportsMap}
              component={lazyReports}
            />
            <Route path={"/elearning"} component={lazyImpreco} />
            <Route exact path="/" component={LazyHome} />
            <Route path="*" component={lazyPageNotFound} />
          </Switch>
          {!history.location.pathname.includes("/elearning") && (
            <Route path="*" component={Footer} />
          )}
        </div>
      </Suspense>
    </>
  ) : (
    <div>"loading..."</div>
  );
}

export default App;
