import { Route, Switch } from 'react-router'
import React, { Suspense } from 'react'
import PageIsLoading from '../Components/Common/PageIsLoading/PageIsLoading'

export default function ExploreRouter() {
    const LazyPhoto = React.lazy(() => import('../Components/Pages/ExplorePakleniIslands/Photo/Photo'))
    const LazyVideo = React.lazy(() => import('../Components/Pages/ExplorePakleniIslands/Video/Video'))
    const LazyPublications = React.lazy(() => import('../Components/Pages/ExplorePakleniIslands/Publications/Publications'))
    const lazyPageNotFound = React.lazy(() => import('../Components/Pages/NoPage/NoPage'))
    return (
        <>
            <Suspense fallback={<PageIsLoading />}>
                <Switch>
                    <Route path="/ExplorePakleniIslands/Photo" component={LazyPhoto} />
                    <Route path="/ExplorePakleniIslands/Video" component={LazyVideo} />
                    <Route path="/ExplorePakleniIslands/Publications" component={LazyPublications} />
                    <Route path="*" component={lazyPageNotFound} />
                </Switch>
            </Suspense>
        </>
    )
}
