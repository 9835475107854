import React from 'react'
import { useLocale } from '../../extensions/Locale/useLocale'
import LanguageButton from '../LanguagePicker/languageButtons'
import POconstants from '../../Utils/POconstants.js'
import { Link } from 'react-router-dom'

export default function ToolBar({ match }: any) {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const isOnImprecoSite = window.location.hostname.includes('impreco')
    return (
        <div className="toolBar">
            {isOnImprecoSite ? (
                <a
                    className="toolBarButton"
                    href={POconstants.baseRoute + POconstants.siteLinks.aboutUs + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}
                >
                    {lang.titles.aboutUs.toLocaleUpperCase()}
                </a>
            ) : (
                <Link className="toolBarButton" to={POconstants.siteLinks.aboutUs + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}>
                    {lang.titles.aboutUs.toLocaleUpperCase()}
                </Link>
            )}
            {isOnImprecoSite ? (
                <a
                    className="toolBarButton"
                    href={POconstants.baseRoute + POconstants.siteLinks.contact + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}
                >
                    {lang.contact.toLocaleUpperCase()}
                </a>
            ) : (
                <Link className="toolBarButton" to={POconstants.siteLinks.contact + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}>
                    {lang.contact.toLocaleUpperCase()}
                </Link>
            )}
            <a
                className="toolBarButton"
                href={POconstants.externalLinks.imprecoMain + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}
            >
                IMPRECO
            </a>
            <a className="toolBarButton" href={POconstants.externalLinks.euProjects} target="_blank" rel="noopener noreferrer">
                {lang.titles.EUProjects.toLocaleUpperCase()}
            </a>
            <LanguageButton />
        </div>
    )
}
