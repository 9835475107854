import { Route, Switch } from 'react-router'
import React, { Suspense } from 'react'
import PageIsLoading from '../Components/Common/PageIsLoading/PageIsLoading'

export default function PakleniIslandsRouter() {
    const GeneralInformation = React.lazy(() => import('../Components/Pages/PakleniIslands/GeneralInformation/GeneralInformation'))
    const NaturalHeritage = React.lazy(() => import('../Components/Pages/PakleniIslands/NaturalHeritage/NaturalHeritage'))
    const CultHistHeritage = React.lazy(() => import('../Components/Pages/PakleniIslands/CultHistHeritage/CultHistHeritage'))
    const GeoPaleoHeritage = React.lazy(() => import('../Components/Pages/PakleniIslands/GeoPaleoHeritage/GeoPaleoHeritage'))
    const lazyPageNotFound = React.lazy(() => import('../Components/Pages/NoPage/NoPage'))
    const SeaFloorAndLighthouses = React.lazy(() =>
        import('../Components/Pages/PakleniIslands/SeaFloorAndLighthouses/SeaFloorAndLighthouses')
    )
    return (
        <>
            <Suspense fallback={<PageIsLoading />}>
                <Switch>
                    <Route path="/PakleniIslands/GeneralInfo/" component={GeneralInformation} />
                    <Route path="/PakleniIslands/NaturalHeritage/" component={NaturalHeritage} />
                    <Route path="/PakleniIslands/CulHisHeritage/" component={CultHistHeritage} />
                    <Route path="/PakleniIslands/GeoPaleoHeritage/" component={GeoPaleoHeritage} />
                    <Route path="/PakleniIslands/seafloor/" component={SeaFloorAndLighthouses} />
                    <Route path="*" component={lazyPageNotFound} />
                </Switch>
            </Suspense>
        </>
    )
}
