import React from 'react'
import { useLocale } from '../../extensions/Locale/useLocale'
import FooterLink from './Components/FooterLink'
import FooterIslands from './FooterLogo.png'
import { RouteComponentProps, Link } from 'react-router-dom'
import POconstants from '../../Utils/POconstants.js'
import { createLangURLFromBaseURL } from '../../Utils/ClientUtil'
import CountyLogo from './Resources/CountyLogo.png'
import MiKLogo_hr from './Resources/MiKLogo_hr.png'
import MiKLogo_en from './Resources/MiKLogo_en.png'

const Footer = (props: RouteComponentProps) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    return (
        <div className="footerContainer">
            <div className="footerGrid">
                <div className="footerLinks">
                    <div className="footerColumn">
                        <img src={FooterIslands} alt={lang.alt.nav.POLogo}></img>
                        <p>{lang.footerText}</p>
                    </div>
                    <div className="footerColumn">
                        <h3>{lang.PakleniIslands.toLocaleUpperCase()}</h3>
                        <FooterLink text={lang.GeneralInfo} link={POconstants.siteLinks.generalInfo} {...props} />
                        <FooterLink text={lang.NatHeritage} link={POconstants.siteLinks.naturalHeritage} {...props} />
                        <FooterLink text={lang.CulHistHeritage} link={POconstants.siteLinks.culHisHeritage} {...props} />
                        <FooterLink text={lang.GeoPaleoHeritage} link={POconstants.siteLinks.geoPaleoHeritage} {...props} />
                        <FooterLink text={lang.SeaFloorAndLighthouses} link={POconstants.siteLinks.seaFloorAndLighthouses} {...props} />
                        <FooterLink
                            text={lang.DidYouSeeThis.toLocaleUpperCase()}
                            link={POconstants.siteLinks.didYouSeeThis}
                            isTitle
                            {...props}
                        />
                        <h3>{lang.ExplorePakleniIslands.toLocaleUpperCase()}</h3>
                        <FooterLink text={lang.Photo} link={POconstants.siteLinks.photo} {...props} />
                        <FooterLink text={lang.Video} link={POconstants.siteLinks.video} {...props} />
                        <FooterLink text={lang.publications} link={POconstants.siteLinks.publications} {...props} />
                    </div>
                    <div className="footerColumn">
                        <h3>{lang.PlanVisit.toLocaleUpperCase()}</h3>
                        <FooterLink text={lang.titles.serviceInfo} link={POconstants.siteLinks.serviceInfo} {...props} />
                        <span style={{ color: 'white' }}>{lang.PlanVisit}</span>
                        <div className="footerSublist">
                            <FooterLink text={lang.Diving} link={POconstants.siteLinks.diving} {...props} />
                            <FooterLink text={lang.titles.romanVilla} link={POconstants.siteLinks.romanVilla} {...props} />
                            <FooterLink text={lang.footpath} link={POconstants.siteLinks.footpath} {...props} />
                            <FooterLink text={lang.Beaches} link={POconstants.siteLinks.beaches} {...props} />
                            <FooterLink text={lang.Sailing} link={POconstants.siteLinks.sailing} {...props} />
                            <FooterLink text={lang.titles.hospitality} link={POconstants.siteLinks.hospitality} {...props} />
                            <FooterLink text={lang.titles.vineyards} link={POconstants.siteLinks.vineyards} {...props} />
                        </div>
                        <FooterLink text={lang.titles.touristMap} link={POconstants.siteLinks.touristMap} {...props} />
                        <FooterLink
                            text={lang.pages.news.news.toLocaleUpperCase()}
                            link={POconstants.siteLinks.news}
                            isTitle
                            {...props}
                        />
                    </div>
                    <div className="footerColumn">
                        <h3>{lang.contact.toLocaleUpperCase()}</h3>
                        <p>
                            {lang.seaAndKarst}
                            <br />
                            <a target="_blank" rel="noopener noreferrer" href="www.moreikrs.hr">
                                www.moreikrs.hr
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:info@moreikrs.hr">
                                info@moreikrs.hr
                            </a>
                            {lang.pages.contact.p3}
                            <br />
                            {lang.pages.contact.p2}
                            <br />
                            {lang.pages.contact.p4}
                            <br />
                            {lang.pages.contact.p5}
                            <div className="logoRow">
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={POconstants.externalLinks.county}
                                    className="SDCountyLink"
                                >
                                    <img
                                        src={CountyLogo}
                                        alt={lang.alt.nav.countyLogo}
                                        style={{ width: '100%', boxSizing: 'border-box', padding: '0.3rem', objectFit: 'contain' }}
                                    ></img>
                                    <div className="countyTitle">{lang.splitDalmatiaCounty.toLocaleUpperCase()}</div>
                                </a>

                                <a rel="noopener noreferrer" target="_blank" href={POconstants.externalLinks.JUSeaAndKarst}>
                                    <img
                                        src={currentLang === 'hr' ? MiKLogo_hr : MiKLogo_en}
                                        alt={lang.alt.nav['MiKLogo']}
                                        style={{
                                            height: '100%',
                                            boxSizing: 'border-box',
                                            padding: '0.3rem',
                                            objectFit: 'contain',
                                            marginRight: '0.3rem',
                                        }}
                                    ></img>
                                </a>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="fesbFooterIconRow">
                    <a className="sAppIcon" href={POconstants.externalLinks.facebook} target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a className="sAppIcon" href={POconstants.externalLinks.instagram} target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="sAppIcon" href={POconstants.externalLinks.youtube} target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
                <div className="footerInfo">
                    <span>Copyright © 2020 JU MORE I KRŠ - All Rights Reserved.</span>
                    <div>
                        <Link to={createLangURLFromBaseURL(POconstants.siteLinks.contact, currentLang)}>{lang.PrivacySettings}</Link>
                        <span>
                            Powered by: DB Touch -{' '}
                            <a href={POconstants.externalLinks.dbtouch} target="_blank" rel="noopener noreferrer">
                                www.dbtouch.com
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
