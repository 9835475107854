import React from 'react'
import { Footer } from './_FooterLink'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useLocale } from '../../../extensions/Locale/useLocale'

const FooterLink: Footer.FooterLinkNS.FooterLink = props => {
    const { currentLang } = useLocale<Locale.Keys>()
    if (props.isExternalLink) {
        return (
            <a
                className="footerLink"
                href={props.link}
                onClick={() => {
                    ReactGA.pageview(props.link)
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.isTitle ? <h3>{props.text}</h3> : props.text}
            </a>
        )
    }
    return (
        <Link className="footerLink" to={props.link + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}>
            {props.isTitle ? <h3>{props.text}</h3> : props.text}
        </Link>
    )
}

export default FooterLink
