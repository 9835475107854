import { Route, Switch } from "react-router";
import React, { Suspense } from "react";
import PageIsLoading from "../Components/Common/PageIsLoading/PageIsLoading";

export default function NewsRouter() {
  const LazyOverview = React.lazy(
    () => import("../Components/Pages/News/News")
  );
  const LazyPage = React.lazy(
    () => import("../Components/Pages/News/Components/NewsPage/Page")
  );
  const lazyPageNotFound = React.lazy(
    () => import("../Components/Pages/NoPage/NoPage")
  );
  return (
    <>
      <Suspense fallback={<PageIsLoading />}>
        <Switch>
          <Route path="/News/Overview" component={LazyOverview} />
          <Route path="/News/Page/:id" component={LazyPage} />
          <Route path="*" component={lazyPageNotFound} />
        </Switch>
      </Suspense>
    </>
  );
}
