const POconstants = {
     //nginx default max is 1MB, if this is increased, the limit on the server ahs to be increased
     IMAGE_UPLOAD_SIZE_LIMIT_IN_MB: 0.95,
     baseRoute: "https://pakleniotoci.eu",
     //UPDATE SITEMAP CONSTANTS WHEN CHANGING ROUTES
     siteLinks: {
          home: "/",
          generalInfo: "/PakleniIslands/GeneralInfo",
          naturalHeritage: "/PakleniIslands/NaturalHeritage",
          culHisHeritage: "/PakleniIslands/CulHisHeritage",
          geoPaleoHeritage: "/PakleniIslands/GeoPaleoHeritage",
          seaFloorAndLighthouses: "/PakleniIslands/seafloor",
          serviceInfo: "/PlanAVisit/RulesAndRegulations",
          video: "/ExplorePakleniIslands/Video",
          photo: "/ExplorePakleniIslands/Photo",
          publications: "/ExplorePakleniIslands/Publications",
          didYouSeeThis: "/HaveYouSeenIt",
          sailing: "/PlanAVisit/Sailing",
          botanicGarden: "/PlanAVisit/BotanicGarden",
          diving: "/PlanAVisit/Diving",
          romanVilla: "/PlanAVisit/RomanVilla",
          footpath: "/PlanAVisit/Footpath",
          beaches: "/PlanAVisit/Beaches",
          hospitality: "/PlanAVisit/Hospitality",
          vineyards: "/PlanAVisit/Vineyards",
          touristMap: "/TouristMap",
          privacy: "/Privacy",
          aboutUs: "/AboutUs",
          contact: "/Contact",
          news: "/News/Overview",
          newsPage: "/News/Page",
          reportsMap: "/ReportsMap",
          educationalProgramme: "/ImprecoCommonEducationalProgramme.pdf",
          CID: "1. Deliverable T3.3.2 Report on educational modules implementation.pdf",
          protectedAreasInventory:
               "DT 1.1.1 Inventory and mapping of species, habitats, ecosystems, ecosystem services.pdf",
          commonMonitoringProtocol: "Common monitoring protocol.zip",
          simplifiedMonitoringProtocol: "4. Simplified monitoring protocol.zip",
          m4es: "6. M4ES.zip",
     },
     externalLinks: {
          quiz: process.env.REACT_APP_QUIZ_LINK,
          impreco: process.env.REACT_APP_IMPRECO_LINK,
          imprecoMain: "https://impreco.pakleniotoci.eu/index.html",
          geoServer: process.env.REACT_APP_GEO_SERVER_LINK,
          facebook: process.env.REACT_APP_FACEBOOK_LINK,
          instagram: process.env.REACT_APP_INSTAGRAM_LINK,
          county: "https://www.dalmacija.hr/",
          youtube: process.env.REACT_APP_YOUTUBE_LINK,
          dbtouch: "http://www.dbtouch.com",
          adriawet: "https://impreco.pakleniotoci.eu/impreco/publicmap.php",
          imprecoRepository: "https://sites.google.com/unicam.it/imprecorepository/home",
          JUSeaAndKarst: "http://www.moreikrs.hr",
          euProjects: "http://moreikrs.hr/projekti",
          imprecoSocialApps: {
               instagram: "https://www.instagram.com/impreco_interreg_adrion/",
               facebook: "https://www.facebook.com/ImprecoAdrion/",
               twitter: "https://twitter.com/impreco1",
               linkedin: "https://www.linkedin.com/company/impreco-interreg-adrion/",
          },
          publications: {
               strategy6105Hvar: "/PU_6105_Hvar_konacni.pdf",
               specStudy: "/Strucna_studija_Pakleni_FINAL_listopad_2023_sažetak.pdf",
               projektImpreco: "https://gorgonija.com/2019/02/07/projekt-impreco/",
               radionicaImprecoHvar:
                    "https://dalmatinskiportal.hr/energija-i-ekologija/lokalna-radionica-projekta-impreco-danas-u-hvaru/41666",
               radionicaImprecoPakleniotoci:
                    "http://www.hvar.hr/portal/lokalna-radionica-projekta-impreco-za-zastitu-paklenih-otoka/",
               protectedAreasImpreco:
                    "https://impreco.adrioninterreg.eu/activities/the-7-pilot-protected-areas-of-impreco",
               plucaMora:
                    "https://www.dalmacijadanas.hr/pluca-mora-livade-posidonije-kod-paklenih-otoka-unistavaju-se-sidrenjem/",
               trajniNadzor: "https://www.scubalife.hr/magazin/vijesti/trajni-nadzor-nad-livadama-posidonije/",
               izneseniRezultati:
                    "https://www.dalmacijadanas.hr/izneseni-rezultati-projekta-impreco-istaknuti-zajednicki-obrazovni-program-za-mlade-te-prva-interaktivna-web-platforma/",
               radionicaImprecoHvar2: "http://www.dalmatian-nature.hr/novosti-detalji/2019/6",
               imprecoSplit:
                    "https://dalmatinskiportal.hr/energija-i-ekologija/u-splitu-predstavljen-projekt-impreco/41536",
          },
     },
     colors: {
          primary: "#144250",
     },
     APIRoutes: {
          newsCro: "/news/cro",
          newsEng: "/news/eng",
          reportSighting: "/reportSighting",
          getReports: "/getReports",
     },
     ACCEPTED_EXTENSIONS: ["svg", "bmp", "png", "jpg", "jpeg"],
};
export default POconstants;
